import pageImageDesktop from '@/assets/images/page-desktop.jpg';
import pageImageMobile from '@/assets/images/page-mobile.jpg';
import { MainImage } from '@/pages/home/index.styles';
import { kakaotalk } from '@/services';
import { useMedia } from '@/hooks';
import ImageMap from 'image-map';

const Index = () => {
  const { md } = useMedia();

  const initImageMap = () => ImageMap('img[usemap]');

  return (
    <div>
      <MainImage
        src={pageImageDesktop}
        alt=""
        useMap="#image-map-desktop"
        hidden={!md}
        draggable={false}
        onLoad={initImageMap}
      />
      <MainImage
        src={pageImageMobile}
        alt=""
        useMap="#image-map-mobile"
        hidden={md}
        draggable={false}
        onLoad={initImageMap}
      />

      {md && (
        <map name="image-map-desktop">
          <area target="" title="" alt="" href={kakaotalk} coords="1694,930,871,691" shape="rect" />
          <area target="" title="" alt="" href={kakaotalk} coords="833,3061,1719,2783" shape="rect" />
        </map>
      )}

      {!md && (
        <map name="image-map-mobile">
          <area target="" title="" alt="" href={kakaotalk} coords="657,449,342,370" shape="rect" />
          <area target="" title="" alt="" href={kakaotalk} coords="328,1888,683,2002" shape="rect" />
        </map>
      )}
    </div>
  );
};

export default Index;
